
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.header {
  height: 5.5rem;
  z-index: 40;
  position: relative;
}

.footer {
  color: white;
}


.slick-dots {
  @apply !flex justify-start w-full p-0
}


.slick-dots li button {
  @apply block w-4 h-4 bg-yellow-600 overflow-hidden rounded-3xl mt-24
}

.slick-dots li.slick-active button {
  @apply bg-red-500 w-5 h-5
}

.slick-dots li button:before {
  font-size: 0px;
}



* {
  box-sizing: border-box;
}

.lawrencium {
  background: #0f0c29;
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
}

.witching_hour {
  background: #c31432; 
  background: -webkit-linear-gradient(to right, #240b36, #c31432); 
  background: linear-gradient(to right, #240b36, #c31432);
}

.amin {
  background: #8E2DE2; 
  background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2); 
  background: linear-gradient(to right, #4A00E0, #8E2DE2);   
}

.yoda {
  background: #FF0099;
  background: -webkit-linear-gradient(to right, #493240, #FF0099); 
  background: linear-gradient(to right, #493240, #FF0099);     
}
.dark_ocean  {
  background: #373B44;  
  background: -webkit-linear-gradient(to right, #4286f4, #373B44); 
  background: linear-gradient(to right, #4286f4, #373B44);    
}
.moonlit_asteroid   {
  background: rgb(15,32,39); 
  background: linear-gradient(90deg, rgba(15,32,39,1) 0%, rgba(32,58,67,1) 50%, rgba(175,187,192,1) 100%);       
}