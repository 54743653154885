.header {
    color: #ffffff; 
    height: 5.5rem;  
}

.btn {
    border-radius: 1.5rem; 
    border-collapse: collapse; 
    color: white;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.25rem; /* 4px */
}


